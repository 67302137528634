;(function(window, document, $) {
	const $window = $(window);
	const $document = $(document);

	const $navigation = $('.nav');
	const $burgerButton = $('.burger-btn');

	const tabletDevice = 1024;

	/**
	 * Handle Menu
	 */

	$burgerButton.on('click', function(){
		const $this = $(this);

		$this.toggleClass('is-open');
		$navigation.slideToggle();

		event.preventDefault();
	});


	/**
	 * Handle Dropdown
	 */

	const $trigger = $('.js-trigger');

	$trigger.on('click touchstart', function(){
		const $this = $(this);

		if ($window.outerWidth() <= tabletDevice) {
			$this.next().slideToggle();
			$this.toggleClass('is-open');

			event.preventDefault();
		}
	});

	$('.nav').find('li').each(function(index, el) {
		const $this = $(this);
		const $thisParent = $this.closest('li');
		const isDropdown = $this.find('ul').length > 0;

		isDropdown ? $thisParent.addClass('has--dropdown') : '';
	});

	/**
	 * Form Validation
	 */

	$('.form form').validate({
		rules: {
			'field-first-name': {
				required: true
			},
			'field-last-name': {
				required: true
			},
			'field-email': {
				required: true,
				email: true
			},
			'field-phone': {
				required: true,
				number: true
			},
			'field-message': {
				required: true
			}
		},
		messages: {
			'field-first-name': {
				required: 'Please enter your first name'
			},
			'field-last-name': {
				required: 'Please enter your last name'
			},
			'field-email': {
				required: 'Please enter your email',
				email: 'Please enter a valid email'
			},
			'field-phone': {
				required: 'Please enter your phone',
				number: 'Please enter a valid phone'
			},
			'field-message': {
				required: 'Please enter a message'
			}
		}
	})

	$('.ajax-contact-form').on('submit', function(e) {
		e.preventDefault();

		let $self = $(this);
		let loading = $self.data('ajaxLoading');
		if ( loading === 'yes' ) {
			return;
		}

		$self.data('ajaxLoading', 'yes');
		let $file = 'lib/contact-form.php';
		doAjax($self, $file)
			.always(() => {
			$self.data('ajaxLoading', 'no');
		});
	});

	$('.form-message form').on('submit', function(e) {
		e.preventDefault();

		let $self = $(this);
		let loading = $self.data('ajaxLoading');
		if ( loading === 'yes' ) {
			return;
		}

		$self.data('ajaxLoading', 'yes');
		let $file = 'lib/blog-form.php';
		doAjax($self, $file)
			.always(() => {
			$self.data('ajaxLoading', 'no');
		});
	});

	function doAjax($form, $file) {
	    let data = $form.serializeArray();
	    let $loader = $form.find('.lds-hourglass');

	    return $.ajax( {
	    	url: $file,
	    	type: 'POST',
	    	data: data,
	    	beforeSend: () => {
	        	$loader.show();
	        	$form.find('.field').removeClass('error');
	    	},
	      	success: (response, text, jQxhr) => {
		        if ( response.status === 'success' ) {
		         	$form[0].reset();
		         	let thankYouMessage = '<p class="form__thank-you-message">Thank you for contacting me!</p>';
		         	$('.section-contact').html(thankYouMessage);
		         	$('.form-message').html(thankYouMessage);
		        } else if ( response.status === 'error-generic' ) {
		         	alert(response.message);
		        } else {
		         	$.each(response.errors, function(key, val){
		            	$form.find('#' + key).addClass('error');
		         	});
		        }

		        return this;
	    	}
	    } )
	    .always(() => {
	    	$loader.hide();
	    });
	}

	/**
	 * Set current state on navigation
	 *
	 */
	$('.nav > ul > li > a').each(function(index, el) {
		const $this = $(this);
		const navElementHref = $this.data('page');
		const currentPageName = typeof(pageName) !== "undefined" ? pageName : '';
		const isCurrentPage = navElementHref === currentPageName;

		if (isCurrentPage) {
			$this.closest('li').addClass('current').siblings().removeClass('current')
		}
	});
})(window, document, window.jQuery);
